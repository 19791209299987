<template>
  <div class="bg cl">
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <div v-for="item in list" class="mylist" :key="item" @click="showinfo">
          <div class="listtop">
            <div class="cl">
              <img src="https://img.yzcdn.cn/vant/apple-1.jpg" class="fl" alt="" />
              <div class="fl">
                <p>系统消息 <span class="zt">8月27日</span></p>
                <p class="em slp">系统将于2020年12月8日晚进行升级维护</p>
              </div>
            </div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>
<script>
import { Dialog } from "vant";
export default {
  data() {
    return {
      active: "0",
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
    };
  },
  mounted() {},
  methods: {
    // 问诊页面
    showinfo() {
         Dialog.alert({
        title: "标题",
        message: "弹窗内容",
      }).then(() => {
        // on close
      });
    },
    onLoad() {
      setTimeout(() => {
        if (this.refreshing) {
          this.list = [];
          this.refreshing = false;
        }

        for (let i = 0; i < 10; i++) {
          this.list.push(this.list.length + 1);
        }
        this.loading = false;

        if (this.list.length >= 40) {
          this.finished = true;
        }
      }, 1000);
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad();
    },
  },
};
</script>
<style scoped>
/deep/.mytab .van-tab--active .van-tab__text {
  color: rgba(255, 255, 255, 1);
}
/deep/.mytab .van-tab--active .van-tab {
  border: none;
  box-sizing: border-box;
}

/deep/.mytab .van-tab {
  border: 1px solid #d8d8d8;
  box-sizing: border-box;
}
/deep/.mytab .van-tab__text {
  z-index: 10;
  color: rgba(0, 0, 0, 1);
  font-size: 14px;
}
/deep/.mytab .van-tabs__line {
  width: 50%;
  height: 100%;
  background: #1daefc;
}
</style>
<style lang="less" scoped>
.mylist {
  .listbot {
    font-size: 14px;
    text-align: left;
    margin-top: 15px;
  }
  .top_right {
    .em {
      margin-right: 15px;
    }
    .zt {
      font-size: 14px;
      color: rgba(29, 174, 252, 1);
    }
    .zt.on {
      font-size: 14px;
      color: #d8d8d8;
    }
  }
  width: 100%;
  background: #ffffff;
  // height: 154px;
  margin-bottom: 10px;
  box-sizing: border-box;
  padding: 16px;
  .listtop {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    .slp {
      width: 270px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
    }
    p {
      text-align: left;
      margin-bottom: 5px;
      // width: 80%;

      .zt {
        float: right;
        font-size: 14px;
        color: rgba(193, 196, 198, 1);
      }
    }
    img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin-right: 8px;
    }
  }
}
.mytab {
  width: 375px;
  height: 48px;
  box-sizing: border-box;
  .van-tab--active {
    .van-tab__text {
      color: rgba(255, 255, 255, 1);
    }
    .van-tab {
      border: none;
      box-sizing: border-box;
    }
  }
  .van-tab {
    border: 1px solid #d8d8d8;
    box-sizing: border-box;
  }
  .van-tab__text {
    z-index: 10;
    color: rgba(0, 0, 0, 1);
    font-size: 14px;
  }
  .van-tabs__line {
    width: 50%;
    height: 100%;
    background: #1daefc;
  }
}
</style>
